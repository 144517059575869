import React from "react"

import Layout from "components/Layout/de"
import { Container, Row, Col } from "react-bootstrap"
// import Image from "components/Image/index"
import style from "styles/global.module.scss"

// import partner images/logos
import team from "./partners/team.jpg"
import audiLogo from "./partners/audi.png"
import mibaLogo from "./partners/miba.png"
import vodafoneLogo from "./partners/vodafone.png"

// import videos
import bearingVideo from "./videos/bearing.mp4"
import rustVideo from "./videos/rust.mp4"
import streetVideo from "./videos/street.mp4"
import woodVideo from "./videos/wood.mp4"

export default function(props) {
  const header = {
    text: "MoonVision Anwendungsfälle",
    description:
      "MoonVision Use Cases in Automobilindustrie, Holzindustrie und Qualitätssicherung",
  }
  return (
    <Layout header={header} location={props.location}>
      <main className="white-background">
        <section className={style.stripeSectors}>
          <Container>
            <Row className="py-md-4">
              <Col md="6" className="order-md-last">
                <video autoPlay loop muted>
                  <source src={streetVideo} type="video/mp4" />
                </video>
              </Col>
              <Col md="6">
                <h2>
                  <small className="text-muted">Automobilindustrie</small>
                  <br />
                  Überprüfen Sie den Straßenzustand mit Oberflächenanalyse für
                  eine intelligentere und bessere Stadtplanung.
                </h2>
                <p>
                  Vodafone Deutschland nutzt die Moonvision Toolbox für die
                  Straßenoberflächeninspektion, Fehlererkennung und
                  Wartungsoptimierung. Durch die frühzeitige Erkennung von
                  Rissen und Schlaglöchern ermöglicht Vodafone den Städten eine
                  bessere Planung ihrer Wartungsarbeiten und hält ihre
                  Infrastruktur in Form.
                </p>
                <p>
                  In Partnerschaft mit: Vodafone
                  <br />
                  <img src={vodafoneLogo} width="120" alt="Logo Vodafone" />
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className={style.stripeSectors}>
          <Container>
            <Row className="py-md-4">
              <Col md="6">
                <video autoPlay loop muted>
                  <source src={woodVideo} type="video/mp4" />
                </video>
              </Col>
              <Col md="6">
                <h2>
                  <small className="text-muted">Holzindustrie</small>
                  <br />
                  Qualitätsprüfung und -kategorisierung
                </h2>
                <p>
                  Wir stellen sicher, dass holzverarbeitende Betriebe nur die
                  richtige Holzqualität verarbeiten.
                  <br />
                  Die MoonVision-Software kann an Ihre Bedürfnisse angepasst
                  werden und unterstützt Sie zuverlässig mit einem
                  vollautomatischen Erkennungsprozess.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className={style.stripeSectors}>
          <Container>
            <Row className="py-md-4">
              <Col md="6" className="order-md-last">
                <video autoPlay loop muted>
                  <source src={rustVideo} type="video/mp4" />
                </video>
              </Col>
              <Col md="6">
                <h2>
                  <small className="text-muted">Qualitätssicherung</small>
                  <br />
                  Rosterkennungsmaßstab
                </h2>
                <p>
                  Ein Metallproduzent analysiert gebrauchte Metallteile auf
                  Qualitätsmerkmale im Nutzungsprozess.
                </p>
                <p>Beispielgröße:</p>
                <ul>
                  <li>11 verwendete Bilder</li>
                  <li>
                    <a
                      href="https://www.invision-news.de/neuheiten/oberflaechenerkennung-mittels-ai/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Invision Artikel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        <section className={style.stripeSectors}>
          <Container>
            <Row className="py-md-4">
              <Col md="6">
                <video autoPlay loop muted>
                  <source src={bearingVideo} type="video/mp4" />
                </video>
              </Col>
              <Col md="6">
                <h2>
                  <small className="text-muted">Qualitätssicherung</small>
                  <br />
                  Analyse der Lageroberfläche
                </h2>
                <p>
                  Die Miba nutzte die Toolbox, um ihre Experten für
                  Oberflächenanalyse und Fehlerbewertung beim Wartungsprozess
                  von Lagern herauszufordern und trainierte die Software, um
                  bessere Ergebnisse zu liefern
                </p>
                <p>
                  In Partnerschaft mit:
                  <br />
                  <img src={mibaLogo} width="80" alt="Logo Miba" />
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className={style.stripeSectors}>
          <Container>
            <Row className="py-md-4">
              <Col md={{ span: 6, order: "last" }}>
                <img src={team} alt="hackathon team" />
              </Col>
              <Col md="6">
                <h2>
                  <small className="text-muted">Automobilindustrie</small>
                  <br />
                  Automatisierte Containererkennung
                </h2>
                <p>
                  Audi Logistics nutzt die Toolbox zur automatisierten Erkennung
                  von Transportbehältern mit Kameras auf ihren Gabelstaplern.
                  Dies ermöglicht eine zeitnahe Inventur der aktuellen
                  Behältermengen auf den verschiedenen Lagerplätzen in
                  Ingolstadt.
                </p>
                <p>
                  In Partnerschaft mit:
                  <br />
                  <img src={audiLogo} width="100" alt="audi logo" />
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  )
}
